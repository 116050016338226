import React, { useEffect, useState } from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col, Spinner, Card, Button, Alert } from 'react-bootstrap';
//import team1 from 'assets/img/team/3.jpg';
import AbsenceThisYear from './AbsenceThisYear';
import SharedFiles from './SharedFiles';
import ActiveUsers from './ActiveUsers';
import CalendarManagement from 'components/app/management-events/CalendarManagement';
import ToDoList from './ToDoList';
import HolidayService from '../../app/holidays/HolidayService';
import EventService from '../../app/management-events/EventService';
import DirectoryService from 'components/app/directory/DirectoryService';
import DocumentService from 'components/app/documents/DocumentService';
import defaultAvatar from 'assets/img/team/avatar.png';
import AnnoucementWidget from 'components/app/annoucements/AnnoucementWidget';
import InternalVacanciesWidget from 'components/app/rolepositions/InternalVacanciesWidget';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css'; // Import default styles

const HRDashboard = () => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [outOfOfficeToday, setOutOfOfficeToday] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [managementEvents, setManagementEvents] = useState([]);
  const [initialEvents, setInitialEvents] = useState([]);
  const [profile, setProfile] = useState();
  const { instance, accounts } = useMsal();
  const [showCalendar, setShowCalendar] = useState(false);
  const [loadingAbsences, setLoadingAbsences] = useState(true);
  const [absencesThisYear, setAbsencesThisYear] = useState([]);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const fetchAbsences = async () => {
    try {
      const { identifier } = DirectoryService.retrieveLoggedProfile();
      const profile = await DirectoryService.retrieveProfile(identifier); // To get the last one
      setProfile(profile);

      const data = await HolidayService.retrieveAbsencesThisYear();
      const response = await HolidayService.getValuesForAbsencesChart(
        profile,
        data
      );
      setAbsencesThisYear(response);
      setLoadingAbsences(false);
    } catch (error) {
      console.error('Error fetching absences:', error); // Handle errors
      setLoadingAbsences(true); // Ensure loading state is updated even on failure
    }
  };

  useEffect(() => {
    fetchAbsences();

    DirectoryService.retrieveUsersByAccount()
      .then(users => {
        const activeUsers = users.map(u => ({
          id: u.identifier,
          name: u.firstName + ' ' + u.lastName,
          avatar: {
            status: u.online === true ? 'online' : 'offline',
            src: u.avatar || defaultAvatar,
            size: '2xl'
          }
        }));
        setActiveUsers(activeUsers);
      })
      .catch(error => setError(error.message));

    DirectoryService.retrieveOutOfOfficeToday()
      .then(users => {
        const outOfOfficeToday = users.map(u => ({
          id: u.identifier,
          name: u.firstName + ' ' + u.lastName,
          avatar: {
            status: u.status || 'offline',
            src: u.avatar || defaultAvatar,
            size: '2xl'
          }
        }));
        setOutOfOfficeToday(outOfOfficeToday);
      })
      .catch(error => setError(error.message));
    DocumentService.retrieveDocumentsByProfile().then(docs => {
      const sharedDocs = docs.map(d => ({
        id: d.identifier,
        img: d.img,
        name: d.name,
        time: d.dateCreated,
        user: d.profile.email,
        border: true
      }));
      setSharedDocs(sharedDocs);
    });

    const fetchEvents = async () => {
      const events = await EventService.retrieveManagementEvents();
      // const holidayRequests = await HolidayService.retrieveHolidayRequests();
      let msEvents = await EventService.retrieveMicrosoftEvents(
        instance,
        accounts
      );
      if (msEvents === undefined) {
        msEvents = [];
      }
      // Merge the two arrays using the spread operator
      const mergedEvents = [...events, ...msEvents];
      setInitialEvents(mergedEvents);
      console.log(initialEvents); // DELETE this logic
      setManagementEvents(mergedEvents);
      setShowCalendar(true);
    };
    fetchEvents();

    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md bg-white rounded p-3',
        scrollTo: true,
        modalOverlayOpeningPadding: 10, // Adds padding to highlight area
        modalOverlayOpeningRadius: 8, // Adds rounded corners to highlight area
        highlightClass: 'shepherd-highlight' // Custom class for highlighted element
      }
    });

    const showGuide = localStorage.getItem('bstShowGuide');
    if (showGuide === 'true') {
      tour.addStep({
        id: 'step-1',
        text: "Welcome to Bright Staff Tool! 🚀 This quick tour will guide you through the key features to help you manage HR tasks efficiently. You'll learn how to navigate the menu, create schedules, track users, access shared files, and more. Click Next to get started!.",
        attachTo: { element: '#topLogo', on: 'right' },
        buttons: [{ text: 'Next', action: tour.next }]
      });

      tour.addStep({
        id: 'step-2',
        text: 'Use the menu to navigate through BST. Here, you can manage Holidays, track Performance, and oversee Leads Management to streamline HR operations.',
        attachTo: { element: '.flex-column.navbar-nav', on: 'right' },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });
      tour.addStep({
        id: 'step-3',
        text: 'Here, you’ll find shortcuts to essential features for faster navigation. You can also enable or disable the Guide Help and manage Demo Users from this menu to customize your experience',
        attachTo: { element: '#topNavRightBar', on: 'right' },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });
      tour.addStep({
        id: 'step-4',
        text: 'Plan and organize work efficiently with Create Schedule. Assign shifts, set availability, and manage team workload with ease.',
        attachTo: {
          element: '.p-0.management-calendar.card-body',
          on: 'bottom'
        },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });
      tour.addStep({
        id: 'step-5',
        text: "Get a quick overview of who's online and currently working. Monitor activity and ensure seamless collaboration across your team.",
        attachTo: { element: '#activeUsers', on: 'left' },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });
      tour.addStep({
        id: 'step-6',
        text: 'Stay on top of your tasks with the To-Do List. Add, manage, and track your HR-related tasks in one place to keep your workflow organized and efficient.',
        attachTo: { element: '#todoList', on: 'bottom' },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });
      tour.addStep({
        id: 'step-7',
        text: 'Post and manage internal job openings effortlessly. Internal Vacancies help you promote career growth within your company.',
        attachTo: { element: '#internalVacancies', on: 'left' },
        buttons: [
          { text: 'Back', action: tour.back },
          { text: 'Next', action: tour.next }
        ]
      });

      tour.addStep({
        id: 'step-8',
        text: 'Access and manage important HR documents in Shared Files. Upload, share, and collaborate on essential resources securely.',
        attachTo: { element: '#shareFiles', on: 'left' },
        buttons: [
          { text: 'Back', action: tour.back },
          {
            text: 'Finish',
            action: () => {
              localStorage.setItem('bstShowGuide', 'false');
              tour.complete();
            }
          }
        ]
      });

      tour.start();
    }
  }, []);
  return (
    <>
      {error}
      <Row className="g-3 mb-3">
        <Col md={6} xxl={4}>
          {!loadingAbsences && !profile?.contract && (
            <>
              <Card lassName="pb-0 text-center fs--1">
                <Card.Header>
                  <h6 className="fs-0 text-capitalize">Holidays</h6>
                </Card.Header>
                <Alert variant="info" key={'info'} className="m-0 p-2">
                  <h6>You haven't set up your holiday entitlement yet.</h6>
                </Alert>
                <Card.Body className="pb-0 text-center">
                  <Button
                    size="m"
                    className="p-2 m-1 fs--1"
                    onClick={() => navigate('/social/directory/my-details')}
                  >
                    Go to your details
                  </Button>
                </Card.Body>
              </Card>
            </>
          )}
          {loadingAbsences ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            profile?.contract && (
              <AbsenceThisYear
                data={absencesThisYear}
                radius={['100%', '87%']}
              />
            )
          )}
        </Col>
        <Col md={6} xxl={8}>
          <AnnoucementWidget />
        </Col>
      </Row>
      <Row className="g-3">
        <Col xxl={8}>
          {showCalendar && <CalendarManagement data={managementEvents} />}
        </Col>
        <Col xxl={4} id="activeUsers">
          <ActiveUsers
            title={'Active users'}
            className="h-100"
            users={activeUsers}
          />
        </Col>
      </Row>
      <br></br>
      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <ToDoList />
        </Col>
        <Col lg={5} xl={4} id="shareFiles">
          <SharedFiles files={sharedDocs.slice(0, 5)} className="h-lg-100" />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={7} xl={8} id="internalVacancies">
          <InternalVacanciesWidget />
        </Col>
        <Col lg={5} xl={4} id="outOfOfficeToday">
          <ActiveUsers
            className="h-40"
            title={'Out of office today'}
            users={outOfOfficeToday}
          />
        </Col>
      </Row>
    </>
  );
};

export default HRDashboard;
