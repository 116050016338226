const jsonSchema = [
  {
    name: 'name',
    label: 'Account Name',
    type: 'text',
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'numberOfEmployees',
        label: '# of Employees',
        required: true,
        type: 'number'
      },
      {
        name: 'usersLimit',
        label: 'Users limit',
        required: true,
        type: 'checkbox'
      }
    ]
  },
  {
    name: 'telephone',
    label: 'Phone Number',
    type: 'text',
    required: false
  },
  {
    name: 'address',
    label: 'Full Address',
    type: 'text',
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'postcode',
        label: 'PostCode',
        required: true,
        type: 'text'
      },
      {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'text'
      }
    ]
  }
];
export default jsonSchema;
