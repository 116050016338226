import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import AccountService from './AccountService';
import schema from './jsonAccountSchema';
import DirectoryService from '../DirectoryService';
import SoftBadge from 'components/common/SoftBadge';

const AccountDetails = () => {
  const { accountIdentifier } = DirectoryService.retrieveLoggedProfile();
  const identifier = accountIdentifier;
  const [modalShow, setModalShow] = useState(false);
  const [account, setAccount] = useState();

  const showValue = attributeName => {
    if (attributeName === 'usersLimit') {
      return (
        <SoftBadge bg="success" className="me-2">
          {'Unlimited Users Account'}
        </SoftBadge>
      );
    }
    return account[attributeName];
  };

  useEffect(() => {
    AccountService.retrieveAccount(identifier)
      .then(data => {
        setAccount(data);
      })
      .catch(error => {
        console.error('Error retrieving Account', error);
      });
  }, []);

  const jsonSchema = schema;
  const headerGenerated = useHeaderGenerator('Account', ' ', []);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Account has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {account && (
        <>
          <Card>
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col>{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default AccountDetails;
