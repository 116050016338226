const AccountService = {
  async saveAccount(formData) {
    try {
      let url = '/api/<your_controller>';
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { accountIdentifier } = profileLogged;
        console.log(profileLogged);
        console.log(accountIdentifier);
        url = '/api/<your_controller>';
        method = 'POST';
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newAccount = await response.json();
        console.log('Account has been updated:', newAccount);
      }
    } catch (error) {
      console.log('Error saving Account', error);
    }
  },
  async retrieveAllAccountByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/<your_controller>' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Account', error);
      throw error;
    }
  },
  async retrieveAccount(identifier) {
    try {
      const response = await fetch('/api/account/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Account', error);
      throw error;
    }
  },
  async deleteAccount(identifier) {
    console.log(identifier);
    try {
      let url = '/api/<your_controller>';
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newAccount = await response.json();
        console.log('Account has been deleted:', newAccount);
      }
    } catch (error) {
      console.log('Error deleting Account', error);
    }
  }
};
export default AccountService;
