const jsonSchema = [
  {
    name: 'assetReference',
    label: 'Asset Reference',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    name: 'serialNumber',
    label: 'Serial Number',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    name: 'item',
    label: 'Item',
    type: 'text',
    showInList: false,
    required: false
  },
  {
    name: 'profile',
    label: 'User',
    type: 'multiselect',
    showInList: false,
    isMulti: false,
    required: true
  }
  // Add more fields as needed
];
export default jsonSchema;
